import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled, {css, down, x} from '@xstyled/styled-components'
import {NavLink} from 'smooth-doc/components'
import {DocSearch} from './DocSearch'
import {AppNav} from './AppNav'
import {ColorModeSwitcher} from "./ColorModeSwitcher"
import logoTSLNTT from "../../../images/logo-transatel-ntt.svg"

const AppHeaderQuery = graphql`
  query AppHeaderCustom {
    logos: allFile(
      filter: {
        sourceInstanceName: { in: ["image", "default-image"] }
        name: { glob: "logo-nav*" }
      }
      sort: { fields: sourceInstanceName, order: DESC }
    ) {
      nodes {
        name
        publicURL
      }
    }

    site {
      siteMetadata {
        title
        docSearch {
          apiKey
          indexName
          appId
        }
        navItems {
          title
          url
        }
      }
    }
  }
`

const Header = styled.header`
    display: block;
    position: fixed;
    top: 0; right: 0; left: 0;
    background: white;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.05);
    z-index: 600;
    margin-top: 0;
    transition: all 0.3s ease !important;
`

const TopHeader = styled.header`
    display: flex;
    flex-wrap: wrap;
    height: 6rem;
    justify-content: flex-end;
    align-items: center;
    background: #F5F7FA;
    color: #373737;
`

const BottomHeader = styled.header`
  justify-content: space-between;
  font-size: 125%;
  max-width: unset;

  ${down(
  'sm',
  css`
      overflow-x: auto;
    `,
)}
`

const MenusTab = styled.div`
    height: inherit;
    --xstyled-colors-on-background: 000;
    a {
      transition: all 0.5s ease;
    }
`

const Logo = styled.span`
    height: 100%;
    display: block;
    background-image: url(${logoTSLNTT});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
`

export function AppHeader() {
  const data = useStaticQuery(AppHeaderQuery)

  return (
    <Header id="header">
      <TopHeader className="top-header">
        <MenusTab className="grid">
          <NavLink
            as="a"
            href={`https://transatel.com`}
            target="_blank"
            rel="noopener noreferrer"
            className="menu-tab">transatel.com</NavLink>
          <NavLink
            as="a"
            href={`https://auriga.transatel.com`}
            target="_blank"
            rel="noopener noreferrer"
            className="menu-tab">Sim management portal</NavLink>
          <NavLink
            as="a"
            href={`https://docs.transatel.com`}
            target="_blank"
            rel="noopener noreferrer"
            className="menu-tab">Knowledge Center</NavLink>
          <NavLink
            as="a"
            href={`https://support.transatel.com`}
            target="_blank"
            rel="noopener noreferrer"
            className="menu-tab">Support</NavLink>
          <NavLink as={ColorModeSwitcher} className="mode-button"/>
        </MenusTab>
      </TopHeader>
      <BottomHeader className="bottom-header">
        <x.div className="grid">
          <x.div row alignItems="center" flexWrap="nowrap">
            <x.div className="space-eater"></x.div>
            <NavLink
              row
              display="inline-flex"
              alignItems="center"
              flexWrap="nowrap"
              to="/"
              className="logo-container">
              <Logo/>
            </NavLink>
            {data.site.siteMetadata.docSearch ? (
              <x.div className="doc-search">
                <DocSearch {...data.site.siteMetadata.docSearch}/>
              </x.div>
            ) : null}
            <AppNav col="auto" px={2}/>
          </x.div>
        </x.div>
      </BottomHeader>
    </Header>
  )
}
