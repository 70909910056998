import { ApiReferences } from "../../../../src/smooth-doc/components/ApiReferences";
import { ApiReference } from "../../../../src/smooth-doc/components/ApiReference";
import authentication from "../../../../pages/docs/images/authentication.svg";
import webhooks from "../../../../pages/docs/images/webhooks.svg";
import { FiExternalLink } from "react-icons/fi";
import * as React from 'react';
export default {
  ApiReferences,
  ApiReference,
  authentication,
  webhooks,
  FiExternalLink,
  React
};