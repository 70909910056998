import styled from "@xstyled/styled-components";

export const ApiReferences = styled.ul`
  list-style: none !important;
  
  > li:last-child {
    border: none;
  }
`

