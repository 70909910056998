import { ApiReferences } from "../../../../src/smooth-doc/components/ApiReferences";
import { ApiReference } from "../../../../src/smooth-doc/components/ApiReference";
import networkSms from "../../../../pages/docs/images/network/sms.svg";
import networkOta from "../../../../pages/docs/images/network/ota.svg";
import networkAttach from "../../../../pages/docs/images/network/attach.svg";
import networkDataSession from "../../../../pages/docs/images/network/data-session.svg";
import networkEvents from "../../../../pages/docs/images/network/events.svg";
import { FiExternalLink } from "react-icons/fi";
import * as React from 'react';
export default {
  ApiReferences,
  ApiReference,
  networkSms,
  networkOta,
  networkAttach,
  networkDataSession,
  networkEvents,
  FiExternalLink,
  React
};