import React from 'react'
import {RedocStandalone} from 'redoc';

export function Api({...props}) {

  function getHeaderMenuHeight() {
    const header = document.getElementById('header');
    if (header) {
      return header.getBoundingClientRect().bottom;
    }
    return 0;
  }

  function adaptMenu() {
    let nodes = document.querySelectorAll(".scrollbar-container label[role=menuitem] span:nth-child(2)");
    nodes.forEach(node => {
      node.innerHTML = node.innerHTML.replaceAll('/', '/<wbr>');
    });
  }

  function adaptTags() {
    adaptTag("beta");
    adaptTag("new");
  }

  function adaptTag(tag) {
    let html = document.querySelector('body');
    let walker = document.createTreeWalker(html, NodeFilter.SHOW_TEXT);
    let node;
    while ((node = walker.nextNode())) {
      let regexp = new RegExp('\\[' + tag + '\\]', 'g');
      if (regexp.test(node.nodeValue)) {
        console.log(node)
        node.nodeValue = node.nodeValue.replace(regexp, '');
        let span = document.createElement('span');
        span.className = tag;
        node.after(span);
      }
    }
  }

  return (
    <div>
      <RedocStandalone specUrl={props.openapi}
                       options={{
                         hideLoading: true,
                         sortEnumValuesAlphabetically: true,
                         menuToggle: true,
                         suppressWarnings: true,
                         requiredPropsFirst: true,
                         showWebhookVerb: false,
                         scrollYOffset: getHeaderMenuHeight
                       }}
                       onLoaded={() => {
                         adaptMenu();
                         adaptTags();
                       }}/>
    </div>
  )
}
