import React from 'react'
import { x, useColorMode } from '@xstyled/styled-components'
import moonIconLight from '../../../images/icon-moon-light.svg'
import sunIconLight from '../../../images/icon-sun-light.svg'
import moonIconDark from '../../../images/icon-moon-dark.svg'
import sunIconDark from '../../../images/icon-sun-dark.svg'
import moonIconMobile from '../../../images/icon-moon-light-mobile.svg'
import sunIconMobile from '../../../images/icon-sun-light-mobile.svg'

const modeIcons = {
  light: moonIconLight,
  dark: sunIconLight,
}

const modeIconsHover = {
  light: moonIconDark,
  dark: sunIconDark,
}

const modeIconsMobile = {
  light: moonIconMobile,
  dark: sunIconMobile,
}

function getInverseMode(mode) {
  return mode === 'light' ? 'dark' : 'light'
}

export const ColorModeSwitcher = React.forwardRef((props, ref) => {
  const [mode, setMode] = useColorMode()
  return (
    <x.button
      ref={ref}
      type="button"
      onClick={() => setMode(getInverseMode)}
      {...props}>
      <img id="icon-light" src={modeIcons[mode]} alt={mode}/>
      <img id="icon-dark" src={modeIconsHover[mode]} alt={mode}/>
      <img id="icon-mobile" src={modeIconsMobile[mode]} alt={mode}/>
    </x.button>
  )
})
