import { ApiReference } from "../../../../src/smooth-doc/components/ApiReference";
import { ApiReferences } from "../../../../src/smooth-doc/components/ApiReferences";
import sims from "../../../../pages/docs/images/sim-management/sims.svg";
import events from "../../../../pages/docs/images/sim-management/events.svg";
import * as React from 'react';
export default {
  ApiReference,
  ApiReferences,
  sims,
  events,
  React
};