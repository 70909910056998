import { x } from '@xstyled/styled-components';
import { Button, ScreenContainer } from 'smooth-doc/components';
import { Link } from "gatsby";
import imageUrl from "../../../../pages/docs/images/404.png";
import manUrl from "../../../../pages/docs/images/man.png";
import signUrl from "../../../../pages/docs/images/sign.png";
import * as React from 'react';
export default {
  x,
  Button,
  ScreenContainer,
  Link,
  imageUrl,
  manUrl,
  signUrl,
  React
};