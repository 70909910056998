import React from 'react'
import styled from "@xstyled/styled-components";

const ApiReferenceEntry = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 15px;
  margin-top: 15px !important;
  border-bottom: 1px solid #f2f3f7;
`
const ApiReferenceImage = styled.img`
  padding: 5px;
`

export const ApiReference = React.forwardRef((props, ref) => (
  <ApiReferenceEntry className="api-reference" style={{'--api-reference-color': `${props.color}`}}>
    <div className="api-reference-image">
      <ApiReferenceImage ref={ref} width={80} height={80} src={props.image} />
    </div>
    <div className="api-reference-text-container">
      <h4 className="api-reference-title">{props.title}{props.new ? <span className="new"/> : ""}{props.beta ? <span className="beta"/> : ""}</h4>
      <p className="api-reference-description">{props.description}</p>
    </div>
    <div className="check-me">
      <a
        className="check-api"
        target={props.target ? props.target : ''}
        rel="noreferrer"
        href={props.url}>Check me</a>
    </div>
  </ApiReferenceEntry>
))

