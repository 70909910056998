import {mdx} from '@mdx-js/react';
import {css, jsx} from '@emotion/react';

const liveCodeScope = {
  css,
  jsx,
  mdx,
};

export default liveCodeScope;
