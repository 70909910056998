import {theme as baseTheme} from 'smooth-doc/src/theme'
import {css} from '@xstyled/styled-components'
import bgImage from '../../images/front-bg.png'
import CenturyGothicPaneuropeanEot from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean.eot'
import CenturyGothicPaneuropeanWoff2 from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean.woff2'
import CenturyGothicPaneuropeanWoff from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean.woff'
import CenturyGothicPaneuropeanTtf from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean.ttf'
import CenturyGothicPaneuropeanEotBold from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean-Bold.eot'
import CenturyGothicPaneuropeanWoff2Bold from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean-Bold.woff2'
import CenturyGothicPaneuropeanWoffBold from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean-Bold.woff'
import CenturyGothicPaneuropeanTtfBold from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean-Bold.ttf'
import CenturyGothicPaneuropeanEotSemiBold from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean-SemiBold.eot'
import CenturyGothicPaneuropeanWoff2SemiBold from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean-SemiBold.woff2'
import CenturyGothicPaneuropeanWoffSemiBold from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean-SemiBold.woff'
import CenturyGothicPaneuropeanTtfSemiBold from '../fonts/CenturyGothicPaneuropean/CenturyGothicPaneuropean-SemiBold.ttf'

export const theme = {
    ...baseTheme,
    colors: {
        ...baseTheme.colors,
        primary: '#008fa4',
        'primary-100': '#80d1d5',
        'primary-200': '#66c7cd',
        'primary-300': '#33b5bc',
        'primary-400': '#1aabb3',
        'primary-500': '#008fa4',
        'primary-600': '#00929a',
        'primary-700': '#008289',
        'primary-800': '#007178',
        'primary-900': '#006167',
        modes: {
          ...baseTheme.colors.modes,
          dark: {
            ...baseTheme.colors.modes.dark,
            background: '#373737',
          },
        }
    },
    global: css`
    html {
      overflow-y: scroll;
      height: 100%;
      min-height: 100%;
      font-size: 62.5%;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }
    
    body {
      transition: 300ms ease-in color, 300ms ease-in background-color;
      margin: 0;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem !important;
      background-color: background;
      color: on-background;
      line-height: 1;
      -webkit-font-smoothing: unset;
      -moz-osx-font-smoothing: grayscale;
    }
    
    div[class*="DocLayout__Container"] {
      line-height: 1.4;
    }
    
    #ocs-events ~ div>table td:first-child {
      width: 30%;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #f7ac00;
    }
    
    aside h4 {
      color: #f7ac00 !important;
    }

    :focus {
      outline: none !important;
    }
    
    #main {
      margin-top: 15.6rem;
    }
    
    /** CUSTOM CSS **/
    
    // Headers
    
    .bottom-header {
      background-color: #373737;
    }
    
    .xstyled-color-mode-dark .bottom-header {
      background-color: #808080;
    }
    
    // Links
    
    #main article  a {
      text-decoration: none;
      font-weight: 500;
    }
    
    // Hover with underline
    #main article a:hover {
      text-decoration: underline;
    }
    
    // TAGS
    .beta {
      background-color: #f7ac00;
      border-radius: 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
    }
    
    .beta::after {
      content: 'BETA'
    }
    
    .new {
      background-color: #66bda1;
      border-radius: 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
    }
    
    .new::after {
      content: 'NEW'
    }
    
    .sync {
      background-color: #f7ac00;
      border-radius: 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
    }
    
    .sync::after {
      content: 'SYNC'
    }
    
    .async {
      background-color: #f7ac00;
      border-radius: 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
    }
    
    .async::after {
      content: 'ASYNC'
    }
    
    .soon {
      background-color: #808080;
      border-radius: 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
    }
    
    .soon::after {
      content: 'SOON'
    }
    
    .recipient {
      background-color: #008fa4;
      border-radius: 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
    }
    
    .recipient::after {
      content: 'RECIPIENT'
    }
    
    .donor {
      background-color: #008fa4;
      border-radius: 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
    }
    
    .donor::after {
      content: 'DONOR'
    }
    
    // CODE
    .inline-code {
      overflow-wrap: break-word;
      background-color: #F5F7FA !important;
      color: #008fa4 !important;
    }
    .xstyled-color-mode-dark .inline-code {
      background-color: #000000 !important;
      color: #65c2c7 !important;
    }
    pre[class*='language-'] code {
      font-family: inherit;
    }

    pre[class*='language-']::before {
      background: #d9d7e0;
      border-radius: 0 0 4px 4px;
      color: #232129;
      font-weight: bold;
      font-size: 12px;
      font-family: inherit;
      letter-spacing: 0.075em;
      line-height: 1;
      padding: 0.25rem 0.5rem;
      position: absolute;
      left: 1rem;
      text-align: right;
      text-transform: uppercase;
      top: 0;
    }

    pre[class~='language-java']::before {
      content: 'java';
      background: #ea2024;
      color: white;
    }
    
    pre[class~='language-js']::before,
    pre[class~='language-javascript']::before {
      content: 'js';
      background: #f7df1e;
    }

    pre[class~='language-jsx']::before {
      content: 'jsx';
      background: #61dafb;
    }

    pre[class~='language-typescript']::before,
    pre[class~='language-ts']::before {
      content: 'ts';
      background: #294e80;
      color: #fff;
    }

    pre[class~='language-tsx']::before {
      content: 'tsx';
      background: #294e80;
      color: #fff;
    }

    pre[class~='language-graphql']::before {
      content: 'GraphQL';
      background: #e10098;
      color: #fff;
    }

    pre[class~='language-html']::before {
      content: 'html';
      background: #005a9c;
      color: #fff;
    }

    pre[class~='language-css']::before {
      content: 'css';
      background: #ff9800;
      color: #fff;
    }

    pre[class~='language-mdx']::before {
      content: 'mdx';
      background: #f9ac00;
      color: #fff;
    }

    pre[class~='language-shell']::before {
      content: 'shell';
    }

    pre[class~='language-sh']::before {
      content: 'sh';
    }

    pre[class~='language-bash']::before {
      content: 'bash';
    }
    
    pre[class~='language-curl']::before {
      content: 'curl';
    }

    pre[class~='language-yaml']::before,
    pre[class~='language-yml']::before {
      content: 'yaml';
      background: #ffa8df;
    }

    pre[class~='language-markdown']::before {
      content: 'md';
    }

    pre[class~='language-json']::before,
    pre[class~='language-json5']::before {
      content: 'json';
      background: linen;
    }

    pre[class~='language-diff']::before {
      content: 'diff';
      background: #e6ffed;
    }

    pre[class~='language-text']::before {
      content: 'text';
      background: #fff;
    }

    pre[class~='language-flow']::before {
      content: 'flow';
      background: #e8bd36;
    }
    
    .gatsby-highlight {
      font-family: Hack, SFMono-Regular, Menlo, Monaco, Consolas,
        'Liberation Mono', 'Courier New', monospace;
      font-variant: no-common-ligatures no-discretionary-ligatures
        no-historical-ligatures no-contextual;

      position: relative;
      z-index: 0;
      margin: 0 0 16px 0;
      overflow: auto;

      .token {
        font-style: normal !important;
      }
    }
    
    // TABLE
    table {
      border-collapse: separate;
      border-spacing: 0 4px;
      margin-top: -4px;
      margin-bottom: 16px;
      width: 100%;
      
      td:first-of-type > img{
        min-width: 16px;
      }
  
      th,
      td {
        margin: 0;
        color: #808080;
        background-color: #F5F7FA;
        border: solid 1px #F5F7FA;
        border-style: solid none;
        padding: 12px;
  
        :first-of-type {
          border-left-style: solid;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
  
        :last-child {
          border-right-style: solid;
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      tr {
        th {
          color: #373737;
          font-weight: bold;
        }
      }
      
      a {
        color: #076076 !important;
      }
    }
    
    .xstyled-color-mode-dark table {
  
      th,
      td {
        color: #d3d3d3;
        background-color: #808080;
        border: solid 1px #808080;
      }
  
      tr {
        th {
          color: #ffffff;
        }
      }
      
      a {
        color: #65c2c7 !important;
      }
    }
    
    // QUOTE
    #main blockquote {
      background-color: #65c2c7 !important;
      color: #fff;
      border: none !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
      
      a {
        color: #076076 !important;
        text-decoration: none;
      }
    }
    
    .xstyled-color-mode-dark  blockquote {
      background-color: #076076 !important;
      
      a {
        color: #65c2c7 !important;
      }
    }
    
    // API REFERENCES

    .api-reference-text-container {
      display: flex;
      flex-direction: column;
      align-self: center;
      flex-grow: 1;
    }
    
    li .api-reference-text-container h4.api-reference-title {
      margin-bottom: 5px;
      color: var(--xstyled-colors-on-background-light,#3f3f46);
    }
    
    li .api-reference-text-container p.api-reference-description {
      font-size: 15px;
      color: #6C6C80;
    }
    
    .xstyled-color-mode-dark li .api-reference-text-container p.api-reference-description {
     color: white;
    }
    
    .api-reference-image {
      margin-right: 20px;
      height: 80px;
      width: 80px;
      min-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
    }
    
    .check-me {
      display: flex;
      flex-direction: column;
      align-self: center;
      flex-grow: 0;
      margin-left: 10px;
    }
      
    a.check-api {
      line-height: 13.5px;
      text-align: center;
      box-sizing: unset;
      font-weight: 400;
      padding: 10.5px 13.5px;
      border-radius: 40px;
      font-size:13.5px;
      margin-right: -8px;
      display: inline-block;
      min-width: 94.5px;
      text-decoration: none;
      -moz-outline-style: none !important;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      transition: all 0.5s ease;
      
      border: 2px solid var(--api-reference-color);
      background-color: transparent;
      color: var(--api-reference-color);
    }
    
    .api-reference:hover a.check-api {
      background-color: var(--api-reference-color);
      color: #fff
    }
    
    .api-reference:hover .api-reference-title, .api-reference:hover .api-reference-description {
      color: #008FA4;
    }
    
    .xstyled-color-mode-dark .api-reference:hover .api-reference-title, .xstyled-color-mode-dark .api-reference:hover .api-reference-description {
      color: #008FA4;
    }
    
    .xstyled-color-mode-dark a.check-api {
      background-color: var(--api-reference-color);
      color: white;
    }
    
    .xstyled-color-mode-dark .api-reference:hover a.check-api {
      background-color: transparent;
      color: white;
      border-color: white;
    }
    
    // Anchor
    .anchor {
      float: left;
      padding-right: 4px;
      margin-left: -20px;
    }
    h1 .anchor svg,
    h2 .anchor svg,
    h3 .anchor svg,
    h4 .anchor svg,
    h5 .anchor svg,
    h6 .anchor svg {
      visibility: hidden;
    }
    h1:hover .anchor svg,
    h2:hover .anchor svg,
    h3:hover .anchor svg,
    h4:hover .anchor svg,
    h5:hover .anchor svg,
    h6:hover .anchor svg,
    h1 .anchor:focus svg,
    h2 .anchor:focus svg,
    h3 .anchor:focus svg,
    h4 .anchor:focus svg,
    h5 .anchor:focus svg,
    h6 .anchor:focus svg {
      visibility: visible;
    }
    
    // Diagram
    
    .statediagram {
      background-color: white;
    }
    
    .mermaid {
      background: white;
      border-radius: 5px;
    }
    
    .mermaid>svg {
      border-radius: 5px;
      margin-left: auto;
      margin-right: auto;
    }
    
    // Image
    picture>img {
     box-shadow: unset !important;
    }
   
    .gatsby-resp-image-wrapper {
      background: white;
      border-radius:5px;
      max-width: unset !important;
      text-align: center;
    }
    .gatsby-resp-image-background-image {
      padding-bottom: unset !important;
      max-width: 600px;
      height: auto !important;
      margin: auto !important;
    }
    .gatsby-resp-image-wrapper > picture > img {
      max-width: 600px;
      height: auto !important;
      margin: auto !important;
      position: relative !important;
    }
    
    .big-img > .gatsby-resp-image-wrapper > picture > img {
      max-width: unset !important;
    }
    
    .img {
      width: 100%;
      background: white;
      border-radius:5px;
      max-width: unset !important;
      text-align: center;
    }
    
    .small-img {
      max-height: 250px !important;
    }

    .extra-small-img {
      max-height: 200px !important;
    }
    
    .medium-img {
      max-height: 400px !important;
    }
    
    // Zoom
    
    .zoomable {
      max-height: 600px;
    }
    
    .xstyled-color-mode-dark.medium-zoom--opened > .medium-zoom-overlay {
      background: #373737 !important;
    }
    
    .medium-zoom--opened > .medium-zoom-overlay,
    .medium-zoom--opened > .medium-zoom-image,
    img.medium-zoom-image--opened {
      z-index: 50;
    }
      
    // Pace
      
    .pace {
      -webkit-pointer-events: none;
      pointer-events: none;

      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }

    .pace-inactive {
      display: none;
    }

    .pace .pace-progress {
      background: #008fa4;
      position: fixed;
      z-index: 2000;
      top: 0;
      right: 100%;
      width: 100%;
      height: 2px;
    }
      
    // REDOC
      
    .redoc-wrap svg{
      display: inherit;
    }

    .redoc-wrap .redoc-markdown table th, .redoc-wrap .redoc-markdown table td, .redoc-wrap .redoc-markdown table tr{
      border: initial;
    }

    .redoc-wrap .redoc-markdown table th:first-of-type, .redoc-wrap .redoc-markdown table td:first-of-type {
      border: 1px solid rgb(221, 221, 221);
    }

    .redoc-wrap .redoc-markdown table th:last-child, .redoc-wrap .redoc-markdown table td:last-child {
      border: 1px solid rgb(221, 221, 221);
    }

    .redoc-wrap table th, .redoc-wrap table td, .redoc-wrap table tr, .redoc-wrap table tr th{
      background-color: unset;
      color: unset;
      border-spacing: unset;
      border-radius: unset;
      border-top: unset;
    }

    .redoc-wrap table th:first-of-type, .redoc-wrap table td:first-of-type {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      border-bottom: unset;
    }

    .redoc-wrap table th:last-child, .redoc-wrap table td:last-child {
      border-right-style: unset;
      border-bottom-right-radius: unset; 
      border-top-right-radius: unset;
    }
      
    .redoc-wrap {
        margin: 0;
        padding: 0;
        background-color: #FFFFFF;
    }
      
    .redoc-wrap img[alt="API Logo"] {
      max-height: 200px;
      max-width: 200px;
      padding: 10px;
    }

    .redoc-wrap h1 {
      color: #008fa4 !important;
      font-size: 1.8em !important;
    }

    .redoc-wrap h2 {
      font-size: 1.6em !important;
    }

    .redoc-wrap h3 {
      font-size: 1.4em !important;
      padding: 0.2em 0px !important;
      margin: 2.5em 0px 1.1em !important;
      color: unset;
    }

    .redoc-wrap h3[id^=request] {
      padding: 0.2em 0px;
      margin: 2.5em 0px 1.1em;
      color: rgb(51, 51, 51);
      font-weight: normal;
    }

    .redoc-wrap .redoc-markdown h3 {
      font-weight: bold;
    }

    .redoc-wrap h4 {
      font-size: 1em !important;
    }

    .redoc-wrap div[id^="tag"] {
      padding-bottom: 0 !important;
    }

    .redoc-wrap div[id^="operation"] {
      padding-top: 1em !important;
    }

    .redoc-wrap div[id^="operation"] ~ div[id^="operation"]{
      padding-top: 40px !important;
    }

    .redoc-wrap p {
      text-align: justify;
    }

    .redoc-wrap label[type="group"] > span {
      color: #f7ac00;
      font-weight: bold;
    }

    .redoc-wrap label.active > span, .redoc-wrap label:hover > span {
      color: #008fa4;
    }

    .redoc-wrap label > span.operation-type, .redoc-wrap label:hover > span.operation-type {
      color: #fff;
    }

    .redoc-wrap a[download], .redoc-wrap a[download]:visited {
      color: #fff !important;
      border-color: #f7ac00;
      background-color: #f7ac00;
      border-radius: 18px;
    }

    .redoc-wrap a[download]:hover {
      color: #f7ac00 !important;
      background-color: #FFF;
    }

    .redoc-wrap a, .redoc-wrap a:visited {
      color: #008FA4 !important;
    }

    .redoc-wrap a:hover {
      color: #f7ac00 !important;
    }

    .redoc-wrap .security-details li {
      margin-bottom: 14px !important;
    }
    
    .redoc-wrap .security-details p {
      margin-top: 0 !important;
      display: contents !important;
    }

    .redoc-wrap .security-details li:last-child > span > p{
      margin-bottom: 0 !important;
    }

    .redoc-wrap .beta {
      line-height: 14px;
      background-color: rgb(247, 172, 0);
      border-radius: 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
    }

    .redoc-wrap .beta::after {
      content: 'BETA'
    }

    .redoc-wrap .new {
      line-height: 14px;
      background-color: rgb(38, 226, 46);
      border-radius: 3px;
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin-left: 5px;
      padding: 2px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 2px;
    }

    .redoc-wrap .new::after {
      content: 'NEW'
    }

    .redoc-wrap .scrollbar-container label[role=menuitem] span:nth-child(2){
      overflow-wrap: break-word;
    }
      
    // MISC
    
    .text-white {
      color:#FFFFFF;
    }

    .text-center {
      text-align:center;
    }
    
    .w-100 {
      width:100%;
    }
    
    .numbered-list {
      counter-reset: listitem;
      list-style: none !important;
    }
    
    .numbered-list>li {
      position: relative;
      padding-left: 30px;
    }
    
    .numbered-list>li:after {
      color: white;
      counter-increment: listitem;
      content: counter(listitem);
      background: #F7AC00;
      font-size: 12px;
      font-weight: 500;
      line-height: 10px;
      text-align: center;
      padding: 5px 0;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 3px;
    }
    
    .menu-tab {
      height: inherit;
      align-items: center;
      justify-content: center;
      padding: 0 4rem;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.82rem;
      text-decoration:none;
    }
    
    .menu-tab:visited {
      color: inherit;
    }
    
    .menu-tab:hover {
      background-color:#F7AC00;
      color:#FFFFFF;
    }
    
    // Dark mode
    .mode-button {
      height: 6rem;
      width: 6.8rem;
      cursor: pointer;
      transition: all 0.5s ease;
      position: relative;
    }
    
    .mode-button img {
      display: block;
      width: 2rem;
      height: 2rem;
      margin: auto;
    }
    
    #icon-light {
      display: block;
    }
    
    #icon-dark {
      display: none;
    }
    
    #icon-mobile {
      display: none;
    }
    
    .mode-button:hover #icon-light {
      display: none;
    }
    
    .mode-button:hover #icon-dark {
      display: block;
    }
    
    .mode-button:hover {
      background: #FFFFFF;
    }
    
    .grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      align-content: stretch;
      padding: 0;
    }
    
    // Logo
    .logo-container {
      flex-grow: 0;
      display:block;
      position: relative;
      width: 26rem;
      height: 6.6rem;
      margin-left: 6rem;
      padding: 1.5rem 0;
      box-sizing: content-box;
    }
    
    .logo-container > * {
      display: block;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  
    .logo-container a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;    
    }
    
    // Algolia
    .doc-search {
      position: absolute;
      right: 15px;
    }
    
    .doc-search input {
      border-radius: 24px;
      width: 315px !important;
    }
    
    .xstyled-color-mode-dark .doc-search input {
      background-color: #373737;
    }
    
    .doc-search div>div:nth-child(3) {
      padding-right: 5px;
    }
    
    :root {
      --docsearch-primary-color: #008fa4;
      --docsearch-logo-color: #008fa4;
    }
    
    body.xstyled-color-mode-dark {
      --docsearch-primary-color: #008fa4;
      --docsearch-logo-color: #008fa4;
      --docsearch-modal-background: #404040;
      --docsearch-searchbox-background: #373737;
      --docsearch-hit-background: #373737;
      --docsearch-searchbox-focus-background: #373737;
      --docsearch-footer-background: #404040;
      --docsearch-logo-color: #fff;
      --docsearch-key-gradient: linear-gradient(-225deg,#d5dbe4,#f8f8f8);
      --docsearch-key-shadow: inset 0 -2px 0 0 #cdcde6,inset 0 0 1px 1px #fff,0 1px 2px 1px rgba(30,35,90,0.4);
    }
    
    kbd {
      color:white;
      --xstyled-colors-control-border: #808080;
      --xstyled-colors-control-background: #808080;
    }
    
    // Landing
    
    // Front banner
    .front-banner {
      background: url(${bgImage}) top center;
      height: 57.5vh;
      background-size: cover;
      position:relative;
      overflow: hidden;
      display: flex;
    }
    
    .front-banner .woman-sim, .front-banner .team {
      position:absolute;
    }
    
    .front-banner .woman-sim {
      bottom: -12.4rem;
      left: 15.8rem;
    }
    
    .front-banner .team {
      bottom: -13.9rem;
      right: 8rem;
    }
    
    .front-banner .woman-sim img {
      width:42.4rem;
    }
    
    .front-banner .team img {
      width:55.4rem;
    }
    
    .look {
      margin: 0 auto;
      align-self: center;
      margin-top:-25px;
    }
    
    .look h1 {
      margin-bottom:20px;
      font-size: 6rem;
      line-height: 7rem;
      font-family: "Century Gothic Paneuropean Regular", sans-serif;
    }
    
    .look h4 {
      margin: 0;
      margin-bottom:34px;
      font-family: "Open Sans", sans-serif;
      font-size: 4rem;
      line-height: 5.5rem;
      font-weight:300;
    }
    
    .ctas {
      display: block;
      text-align: center;
      line-height: 1;
    }
    .ctas a {
      box-sizing: unset;
      font-weight: 400;
      padding: 1.4rem 1.8rem;
      border-radius: 40px;
      font-size:1.8rem;
      margin: 0 18px;
      display: inline-block;
      min-width: 12.6rem;
      text-decoration: none;
      -moz-outline-style: none !important;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    
    .ctas a:hover {
      background:transparent;
    }
    
    .ctas a.white {
      background-color:#FFFFFF;
      color:#008FA4;
      border:2px solid #FFFFFF;
    }
    
    .ctas a.white:hover {
      background:transparent;
      color:#FFFFFF;
    }
    
    .ctas a.anthr {
      background-color:#373737;
      color:#FFFFFF;
      border:2px solid #373737;
    }
    
    .ctas a.anthr:hover {
      background:transparent;
      color:#373737;
    }
    
    // Quick navigation
    #quick-navigation {
      padding:0;
      width: 100%;
    }
    #quick-navigation > .container {
      max-width:100%;
    }
    #quick-navigation > .container ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    #quick-navigation > .container ul li a {
      font-size:2rem;
      color:#373737;
      line-height:26px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      text-decoration: none;
      -moz-outline-style: none !important;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    .xstyled-color-mode-dark #quick-navigation > .container ul li a {
        color:#FFFFFF;
    }
    a.faq-list-question {
      font-size:2rem;
      color:#373737;
      line-height:26px;
      position: relative;
      top: 2px;
    }
    a.faq-list-question:hover {
      text-decoration:underline;
    }
    .arrow-list-faq img {
      width:8px;
      height:16px;
    }
    #quick-navigation > .container ul li a.current-item {
      color:#008FA4;
    }
    
    #quick-navigation > .container ul li a.current-item svg path {
      fill:#008FA4;
    }
    
    .xstyled-color-mode-dark #quick-navigation > .container ul li a.current-item {
      color:#51b8c0;
    }
    
    .xstyled-color-mode-dark #quick-navigation > .container ul li a.current-item svg path {
      fill:#51b8c0;
    }
    
    .xstyled-color-mode-dark #quick-navigation > .container ul li a svg path {
      fill:#FFFFFF;
    }
    
    #quick-navigation > .container ul li a:hover, #quick-navigation > .container ul li a:hover svg path, #quick-navigation > .container ul li a:hover p {
      color:#008FA4;
      fill:#008FA4;
      transition: all 0.5s ease;
    }
    
    .xstyled-color-mode-dark #quick-navigation > .container ul li a:hover, .xstyled-color-mode-dark #quick-navigation > .container ul li a:hover svg path, .xstyled-color-mode-dark #quick-navigation > .container ul li a:hover p {
      color:#51b8c0;
      fill:#51b8c0;
      transition: all 0.5s ease;
    }
    
    #quick-navigation > .container ul#menu-blocks-nav-cwp li a:hover, #quick-navigation > .container ul#menu-blocks-nav-cwp li a:hover svg path, #quick-navigation > .container ul#menu-blocks-nav-cwp-fr li a:hover, #quick-navigation > .container ul#menu-blocks-nav-cwp-fr li a:hover svg path {
      color:#076076;
      fill:#076076;
    }
    
    .xstyled-color-mode-dark #quick-navigation > .container ul#menu-blocks-nav-iot li a:hover, .xstyled-color-mode-dark #quick-navigation > .container ul#menu-blocks-nav-iot li a:hover svg path, .xstyled-color-mode-dark #quick-navigation > .container ul#menu-blocks-nav-iot-fr li a:hover, .xstyled-color-mode-dark #quick-navigation > .container ul#menu-blocks-nav-iot-fr li a:hover svg path {
      color:#51B8C0;
      fill:#51B8C0;
    }
    
     #quick-navigation > .container ul {
       justify-content: space-between;
       display: grid;
       grid-template-columns: repeat(6, 1fr);
       justify-content: space-between;
    }
   
    #quick-navigation > .container ul li:nth-child(odd) a {
      background-color:#F5F7FA;
    }
    #quick-navigation > .container ul li:nth-child(even) a {
      background-color:#FFFFFF;
    }
    .xstyled-color-mode-dark #quick-navigation > .container ul li:nth-child(odd) a {
      background-color:#373737;
    }
    .xstyled-color-mode-dark #quick-navigation > .container ul li:nth-child(even) a {
      background-color:#404040;
    }
    #quick-navigation > .container ul li a span:first-child {
      order: 1;
      padding: 10px 0 0 0;
    }
    #quick-navigation > .container ul li a span:last-child {
      order: 2;
      padding:0;
    }
    
    #quick-navigation > .container ul li a {
      height:24.2rem;
      padding:0 15px;
    }
    
    #quick-navigation > .container ul li p {
      font-size: 1.8rem;
      line-height: 2.4rem;
      color:#808080;
      margin-top: 0.8rem;
    }
    
    .xstyled-color-mode-dark #quick-navigation > .container ul li p {
      color:#FFFFFF;
    }
    
    #quick-navigation .menu-blocks-nav-iot-fr-container li:last-child a, #quick-navigation .menu-blocks-nav-iot-container li:last-child a{
        background-color: #51b8c0 !important;
      color:#FFFFFF !important;
    }
    
    #quick-navigation .menu-blocks-nav-iot-fr-container li:last-child a:hover, #quick-navigation .menu-blocks-nav-iot-container li:last-child a:hover {
      color:#373737 !important;
    }

    .menu-image-title-after.menu-image-not-hovered img, .menu-image-hovered.menu-image-title-after .menu-image-hover-wrapper, .menu-image-title-before.menu-image-title {
        position: relative;
        top: -2px;
        padding-right: 0;
        display: inline-block;
        width: 3.4rem;
        text-align: center;
        margin-right: 5px;
    }
    
    .header-navigation .menu-image-hover-wrapper .menu-image {
        display: none !important;
    }
    
    .header-navigation .menu-image-hover-wrapper img.hovered-image {
        margin-left: 0 !important;
    }
    
    // Footer
    
    #footer {
      background-color:#373737;
      color:#FFFFFF;
      text-align:center;
      padding:20px 0;
      font-size:2rem;
      width: 100%;
    }
    
    #footer a {
      text-decoration:none;
      outline:none;
      color:#FFFFFF;
      -moz-outline-style: none !important;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
    
    #footer a:hover {
      text-decoration:underline;
    }
    
    // Fonts
    
    @font-face {
        font-family: 'Century Gothic Paneuropean Regular';
        src: url(${CenturyGothicPaneuropeanEot});
        src: url(${CenturyGothicPaneuropeanEot}) format('embedded-opentype'),
        url(${CenturyGothicPaneuropeanWoff2}) format('woff2'),
        url(${CenturyGothicPaneuropeanWoff}) format('woff'),
        url(${CenturyGothicPaneuropeanTtf}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Century Gothic Paneuropean Bold';
        src: url(${CenturyGothicPaneuropeanEotBold});
        src: url(${CenturyGothicPaneuropeanEotBold}) format('embedded-opentype'),
        url(${CenturyGothicPaneuropeanWoff2Bold}) format('woff2'),
        url(${CenturyGothicPaneuropeanWoffBold}) format('woff'),
        url(${CenturyGothicPaneuropeanTtfBold}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Century Gothic Paneuropean SemiBold';
        src: url(${CenturyGothicPaneuropeanEotSemiBold});
        src: url(${CenturyGothicPaneuropeanEotSemiBold}) format('embedded-opentype'),
        url(${CenturyGothicPaneuropeanWoff2SemiBold}) format('woff2'),
        url(${CenturyGothicPaneuropeanWoffSemiBold}) format('woff'),
        url(${CenturyGothicPaneuropeanTtfSemiBold}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
   
    // Responsive
    @media only screen and (max-width: 583px) {
      .top-header .grid {
        width: 100%;
      }
      .menu-tab {
        padding: 0 !important;
        flex-basis: 0;
        flex-grow: 1;
      }
    }
    
    @media screen and (max-width:641px) {
      .look h1 {
        font-size: 5rem;
        line-height: 5.8rem;
      }
      .look h4 {
        font-size: 3rem;
        line-height: 4rem;
      }
      #quick-navigation > .container ul {
        justify-content: space-between;
        grid-template-columns: repeat(2, minmax(50%, 1fr));
      }
      #quick-navigation > .container ul li:nth-child(odd) a {
        background-color:initial;
      }
      #quick-navigation > .container ul li:nth-child(even) a {
        background-color:initial;
      }
      #quick-navigation > .container ul li:first-child a {
        background-color:#F5F7FA;
      }
      #quick-navigation > .container ul li:nth-child(2) a {
        background-color:#FFFFFF;
      }
      #quick-navigation > .container ul li:nth-child(3) a {
        background-color:#F5F7FA;
      }
      #quick-navigation > .container ul li:nth-child(4) a {
        background-color:#F5F7FA;
      }
    }
    
    @media only screen and (max-width: 1024px) {
    
        #main {
          margin-top: 13rem;
        }
        
        .container-404 .not-found {
          height: 50% !important;
          bottom: 50% !important;
          transform: translate(-50%, 50%) !important;
        }
        
        .container-404 .sign {
          height: 35%;
          transform: translate(-420px, -100px)  !important;
        }
        
        .container-404 .man {
          height: 80%;
          transform: translate(230px, -100px) !important;
        }
        
        .container-404 .lost {
          margin-top: 0 !important;
        }

        .top-header {
          height: 45px;
          font-size: 75%;
          justify-content: flex-start;
        }
        
        .top-header .mode-button {
          display: none;
        }
        
        .top-header .grid {
          flex-flow: row;
        }
        
        .bottom-header {
          height: unset;
        }
        
        .bottom-header .mode-button {
          height: 100%;
        }
        
        .bottom-header #icon-light {
          display: none;
        }
        
        .bottom-header #icon-dark {
          display: none;
        }
        
        .bottom-header #icon-mobile {
          display: block;
        }
        
        .mode-button:hover #icon-light {
          display: none;
        }
        
        .mode-button:hover #icon-mobile {
          display: none;
        }
        
        .mode-button:hover #icon-dark {
          display: block;
        }
        
        .menu-tab {
          padding: 0 2rem;
          line-height: 1.32rem;
          transition:all 0s ease !important;
        }
        
        .bottom-header .grid>div {
          justify-content: space-between;
        }
        
        .logo-container {
          margin-left: 0;
          width: 210px;
          height: 5.6rem;
          padding: 1.5rem 0;
        }
        
        .space-eater {
          width: 74px;
        }
        
        .doc-search {
          display: none;
        }
        
        .front-banner {
          height:auto;
          padding: 7rem 20px 6rem 20px;
        }
        
        .menu-image-title-after.menu-image-not-hovered img, .menu-image-hovered.menu-image-title-after .menu-image-hover-wrapper, .menu-image-title-before.menu-image-title {
          width: 24px;
        }
        
        .menu-image-hover-wrapper img.hovered-image {
          opacity:1;
        }
    }
    
    @media screen and (min-width:642px) and (max-width:1067px) {
      #quick-navigation > .container ul {
        justify-content: space-between;
        grid-template-columns: repeat(3, minmax(33.33333%, 1fr));
      }
    }
    
    @media screen and (max-width:992px) {
      #quick-navigation .row, #quick-navigation .row > div  {
        padding:0;
        margin:0;
      }
    }
    @media screen and (min-width:1025px) and (-webkit-min-device-pixel-ratio: 1.2) {
      .menu-image-title-after.menu-image-not-hovered img, .menu-image-hovered.menu-image-title-after .menu-image-hover-wrapper, .menu-image-title-before.menu-image-title {
        width: 3rem;
      }
    }
    
    @media screen and (min-width:1025px) and (-webkit-min-device-pixel-ratio: 1.45) {
      html {
        font-size:46.875%;
      }
      
      #main {
        margin-top: 14.6rem;
      }
      
      .logo-container {
        position: relative;
        width: 25rem;
        height: 5.6rem;
        margin-left: 6rem;
        padding: 1.5rem 0;
        box-sizing: content-box;
      }
      
      .menu-tab {
        font-size: 1.4rem;
        line-height: 1.62rem;
      }
    }

    @media screen and (min-width:1068px) and (-webkit-min-device-pixel-ratio: 1.45) {
      #quick-navigation > .container ul li a {
        font-size: 1.8rem;
        line-height:2.4rem;
      }
      #quick-navigation > .container ul {
        justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        justify-content: space-between;
      }
      #quick-navigation > .container ul li a {
        height:20rem;
        padding:0 15px;
      }
      #quick-navigation>.container ul li a span:last-child img+img {
        height: 6rem;
        position: relative;
        left: 0%;
        margin-left: 0rem!important;
      }
      #quick-navigation>.container ul li a span:last-child svg {
        height: 6rem;
        position: relative;
        left: 50%;
        transform: translatex(-50%);
      }
      #quick-navigation > .container ul li p {
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: #808080;
        margin-top: 0.8rem;
      }
      #quick-navigation > .container ul li a span:first-child {
        order: 1;
        padding: 6px 0 0 0;
      }
      #quick-navigation > .container ul li a span:last-child img {
        height:6rem;
        position: relative;
        left: 50%;
        transform: translatex(-50%);
      }
      #quick-navigation > .container ul li a span:last-child img + img {
        height:6rem;
        position: relative;
        left:0%;
        margin-left:-0rem !important;
      }
    }
   
    @media screen and (min-width:1025px) and (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.40) {
      html {
        font-size : 53.125%;
      }
    }
    
    @media only screen and (min-width: 1025px) {
        
        .bottom-header .mode-button {
          display: none;
        }
        
        .bottom-header img {
          height: 100%;
        }
    }
    
    @media screen and (max-width:1900px) {
      .front-banner .woman-sim {
        left:5.8rem;
      }
      .front-banner .team {
        right: -6rem;
      }
    }
    
    @media screen and (max-width:1736px) and (-webkit-min-device-pixel-ratio: 1.45) {
      .front-banner .woman-sim {
        bottom: -7.4rem;
        left:15.8rem;
      }
      .front-banner .team {
        bottom: -10.9rem;
        right: 4rem;
      }
      .front-banner .woman-sim img {
        width: 33.4rem !important;
      }
      .front-banner .team img {
        width: 45.4rem !important;
      }
    }

    @media screen and (max-width:1736px) and (-webkit-min-device-pixel-ratio: 1.2) {
      .front-banner .woman-sim {
        bottom: -7.4rem;
        left:15.8rem;
      }
      .front-banner .team {
        bottom: -10.9rem;
        right: 4rem;
      }
      .front-banner .woman-sim img {
        width: 33.4rem !important;
      }
      .front-banner .team img {
        width: 45.4rem !important;
      }
    }

    @media screen and (max-width:1736px) {
      .front-banner .woman-sim {
        bottom: -7.4rem;
      }
      .front-banner .team {
        bottom: -10.9rem;
      }
      .front-banner .woman-sim img {
        width: 33.4rem;
      }
      .front-banner .team img {
        width: 45.4rem;
      }
    }
    
    @media screen and (max-width:1326px) {
      .front-banner .woman-sim {
        bottom: -6.4rem;
      }
      .front-banner .team {
        bottom: -8.9rem;
      }
      .front-banner .woman-sim img {
        width: 26.4rem;
      }
      .front-banner .team img {
        width: 39.4rem;
      }
    }
    
    @media only screen and (max-width: 1160px) {
      .front-banner .woman-sim, .front-banner .team {
        display: none;
      }
    }
    
    @media screen and (max-width:840px) {
      br.view-dekstop {
        display: none;
      }
      
      .container-404 .not-found {
        height: 40% !important;
      }
      
      .container-404 .sign {
        height: 30%;
        transform: translate(-350px, -150px)  !important;
      }
      
      .container-404 .man {
        height: 60%;
        transform: translate(190px, -150px) !important;
      }

    }
    
    @media only screen and (max-width: 600px) {
      .container-404 .not-found {
        height: 30% !important;
      }
      
      .container-404 .sign {
        height: 25%;
        transform: translate(-300px, -160px)  !important;
      }
      
      .container-404 .man {
        height: 50%;
        transform: translate(140px, -160px) !important;
      }
    }
    
    @media only screen and (max-width: 480px) {
      .ctas a.mb-20 {
          margin-bottom: 20px;
      }
    }
    
    @media only screen and (max-width: 414px) {
      .container-404 .not-found {
        height: 20% !important;
      }
      
      .container-404 .sign {
        height: 15%;
        transform: translate(-170px, -205px)  !important;
      }
      
      .container-404 .man {
        height: 35%;
        transform: translate(90px, -205px) !important;
      }
    }
    
    @media only screen and (max-width: 768px) {
      .check-me a {
        padding: 10.5px .5px;
      }
      .api-reference-image {
        height: 60px;
        width: 60px;
        min-width: 60px;
      }
    }
  `,
}
