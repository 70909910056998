import React from 'react'
import {NavLink} from 'smooth-doc/components'
import {ColorModeSwitcher} from "./ColorModeSwitcher";

export function AppNav() {

  return (
    <NavLink as={ColorModeSwitcher} className="mode-button"/>
  )
}
