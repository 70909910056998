import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/300.css'
import mediumZoom from 'medium-zoom';
export { wrapPageElement } from './src/gatsby/wrapPageElement';

function zoomImages() {
  let imageElements = Array.from(document.querySelectorAll('.gatsby-resp-image-image'));
  let zoomableElements = Array.from(document.querySelectorAll('.zoomable'));
  mediumZoom(imageElements.concat(zoomableElements));
}

export const onRouteUpdate = (_, pluginOptions) => {
  zoomImages();
}
