import { ApiReference } from "../../../../src/smooth-doc/components/ApiReference";
import { ApiReferences } from "../../../../src/smooth-doc/components/ApiReferences";
import ocsCatalog from "../../../../pages/docs/images/ocs/catalog.svg";
import ocsInventory from "../../../../pages/docs/images/ocs/inventory.svg";
import ocsSubscriptions from "../../../../pages/docs/images/ocs/subscriptions.svg";
import ocsEvents from "../../../../pages/docs/images/ocs/events.svg";
import { FiExternalLink } from "react-icons/fi";
import * as React from 'react';
export default {
  ApiReference,
  ApiReferences,
  ocsCatalog,
  ocsInventory,
  ocsSubscriptions,
  ocsEvents,
  FiExternalLink,
  React
};